.Home {
  text-align: left;
}

a:link {
  color: #1e5128;
}

a:visited {
  color: #1e5128;
}

a:hover {
  color: #4e9f3d;
}
